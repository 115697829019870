import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import './HomePage.css';

function HomePage() {
  const { conneoId } = useParams();
  const navigate = useNavigate();
  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    document.title = `Conneo Scan > magazijn`;
    // Automatically focus the input field when the component is mounted
    document.getElementById('barcode-input').focus();
  }, []);

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter' || event.keyCode === 13 || event.keyCode === 10) {
      handleSubmit();
    }
  };

  const handleSubmit = () => {
    let processedValue = inputValue.trim();
    // Check if the input is a full URL
    try {
      const url = new URL(processedValue);
      // Extract the last segment after the last '/' in the URL
      processedValue = url.pathname.split('/').filter(Boolean).pop();
    } catch (error) {
      // If input is not a valid URL, we just use it as it is
      // No need to handle anything here as the input will be used as is
    }

    if (processedValue !== '') {
      // Redirect to /<conneoId>/<processed_value> when the input is submitted
      navigate(`/${conneoId}/${processedValue}`);
    }
  };

  return (
    <div className="home-page">
      <div className="input-container">
        <input
          id="barcode-input"
          type="text"
          value={inputValue}
          onChange={handleInputChange}
          onKeyPress={handleKeyPress}
          placeholder="Scan QR code"
          className="barcode-input"
        />
        <button onClick={handleSubmit} className="btn-confirm">
          Bevestigen
        </button>
      </div>
    </div>
  );
}

export default HomePage;
