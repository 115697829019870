import React from 'react';
import { BrowserRouter as Router, Route, Routes, useParams } from 'react-router-dom';
import ArticleList from './components/ArticleList';
import HomePage from './components/HomePage'; // Import the new HomePage component

import './App.css';
import './components/Article.css';
import './components/ArticleList.css';
import './UnauthenticatedMessage.css'; 

function App() {
  return (
    <Router>
      <div className="App">
        <header className="App-header">
          <h4>Conneo Scan {'>'} magazijn</h4>
        </header>
        <Routes>
          <Route path="/" element={<UnauthenticatedMessage />} />
          <Route path="/:conneoId" element={<HomePage />} />
          <Route path="/:conneoId/:batchId" element={<ArticleListWrapper />} />
        </Routes>
      </div>
    </Router>
  );
}

function UnauthenticatedMessage() {
  document.title = `Conneo Scan > magazijn`;
  return (
    <div className="unauthenticated-container">
      <div className="unauthenticated-icon">🔒</div>
      <h2 className="unauthenticated-title">Access Denied</h2>
      <p className="unauthenticated-text">
        You are not authenticated to view this page. Please provide a valid <strong>Batch ID</strong> to proceed.
      </p>
    </div>
  );
}

function ArticleListWrapper() {
  document.title = `Conneo Scan > magazijn`;
  const { conneoId, batchId } = useParams();

  if (!batchId) {
    return <UnauthenticatedMessage />;
  }

  return <ArticleList conneoId={conneoId} batchId={batchId} />;
}

export default App;
